@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: "Muli";
  src: url(../public/font/Muli-Regular.ttf) format("truetype");
}

body {
  margin: 0;
  @apply bg-[#F4F6FF];
  font-family: "Muli";
}

.progress-ring__circle {
  stroke-dasharray: 400, 400;
  transition: stroke-dashoffset 0.35s;
  transform: rotate(90deg);
  transform-origin: 50% 50%;
}
